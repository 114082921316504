export const environment = {
  production: false,
  name: 'development',
  version: '0.0.1',
  app: {
    url: 'https://development.waterconsumption.de'
  },
  api: {
    url: 'https://api.development.waterconsumption.de:8886/api/v1'
  }
}
